<template lang="pug">
.wrapper(v-if="!isActivAccessibility")
    .row.justify-between.q-my-lg
        router-link.font-32.line-height-44.text-weight-bold.text-primary.decoration-no(
            :to="city ? `/${city?.slug}/articles` : '/articles'"
            title="Статьи" itemprop="text") Полезные статьи
        router-link.font-32.text-weight-bold.text-positive.line-height-44.link(:to="city ? `/${city?.slug}/articles` : '/articles'" v-if="articles?.results?.length > 4" title="Все статьи")
            | Все статьи  
    .relative-position.q-mb-lg
        q-btn(
            fab-mini
            @click="goPrev"
            v-if="prev"
            :style="standartPreview ? '' : 'left: 50px !important'").row.justify-center.items-center.prev
            q-icon(
                name="etabl:arrow_prev"
                size="xs")
        q-btn(
            fab-mini
            @click="goNext"
            v-if="next"
            :style="standartPreview ? '' : 'right: 50px !important'").row.justify-center.items-center.next
            q-icon(
                name="etabl:arrow_next"
                size="xs")
        q-scroll-area(
            ref="slider"
            :thumb-style="thumbStyle"
            :bar-style="thumbStyle"
            @scroll="scroll"
            :style="`height: 40px;`")
            .row.no-wrap
                .tag.text-weight-medium.cursor-pointer.q-mr-sm.text-grey-9.border-grey-9(
                    v-for="(tag, index) in articlesTags.tags"
                    :key="index"
                    @click="setTag(tag.id)")
                    | {{ tag.name }}
    .row(:class="articles?.results?.length > 4 ? 'justify-between' : ''")
        router-link.link.column.text-primary.article(
            v-for="(item, index) of articles?.results"
            :key="index"
            :style="articles?.results?.length > 4 ? '' : 'margin-right: 36px;'"
            :to="city ? `/${city?.slug}/article/${item.slug}` : `article/${item.slug}`"
            :title="`${item.slug || ''}`"
        )
            template(v-if="index < articlesCount")
                img.article__img(
                    :src="item.imagePreviewCdn" loading="lazy"
                )
                .font-16.line-height-24.text-weight-bold.q-mt-md.article__name(
                    style="overflow: hidden;"
                )
                    | {{ item.title }}
                .line-height-20.q-mt-sm.article__title
                    | {{ item.previewText }}
                .row.justify-between.q-mt-sm.text-grey-9
                    | {{ arcticleDate(item.dateOfPublication) }}
                    .row
                        q-icon(
                            name="etabl:chat-round"
                            size="20px"
                            color="grey-9"
                        ).q-mr-sm
                        span {{ item.totalReviews }}
                        q-icon(
                            name="etabl:eye"
                            size="20px"
                            color="grey-9"
                        ).q-mr-sm.q-ml-lg
                        span {{ item.totalViews }}
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: 'ArticlesDesktop',
    props: {
        articles: {
            type: Object,
            default: () => null,
            required: true,
        },
        standartPreview: {
            type: Boolean,
            default: () => false,
        },
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();

        const slider = ref(null);
        const next = ref(true);
        const position = ref(0);
        const windowWidth = ref(0);

        // отображение кнопки назад
        const prev = computed(() => position.value > 0);

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);
        const isActivAccessibility = computed(() => store.getters['styles/isActiv']);

        const articlesTags = computed(() => store.getters['advertisement/ARTICLES_TAGS']);

        const showFull = ref(false);

        // стили для полосы прокрутки
        const thumbStyle = computed(() => {
            return {
                width: 0,
                opacity: 0,
            };
        });

        const arcticleDate = (value) => {
            let date = new Date(value);

            return date.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        };

        const goTo = (link) => {
            router.push(`/article/${link}`);
        };

        const articlesCount = computed(() => {
            if (windowWidth.value >= 1280) return 4;
            else return 3;
        });

        // метод прокрутки назад
        const goPrev = () => {
            position.value -= 1146;

            slider.value.setScrollPosition('horizontal', position.value, 300);
        };

        // метод прокрутки вперед
        const goNext = () => {
            position.value += 1146;

            slider.value.setScrollPosition('horizontal', position.value, 300);
        };

        // отображение кнопки вперёд
        const scroll = (source) => {
            next.value = !!(source.horizontalPercentage < 0.9989);
        };

        const setTag = (value) => {
            router.push(city.value ? `/${city.value.slug}/articles?id=${value}` : `/articles?id=${value}`);
        };
        const handleResize = () => {
            windowWidth.value = window.innerWidth;
        };
        onMounted(()=>{
            handleResize();
            window.addEventListener('resize', handleResize);
        });
        return {
            goTo,
            showFull,
            city,
            isActivAccessibility,
            articlesCount,
            articlesTags,
            slider,
            next,
            prev,
            position,
            goNext,
            goPrev,
            scroll,
            thumbStyle,
            arcticleDate,
            setTag,
        };
    },
};
</script>

<style lang="scss" scoped>
.article {
    width: 300px;

    &__img {
        height: 200px;
        border-radius: 16px;
    }

    &__name {
        height: 48px;
        overflow: hidden;
    }

    &__title {
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
}

.tag {
    padding: 4px 16px;
    border-radius: 16px;
    height: 32px;
    white-space: nowrap;
}

:deep(.q-scrollarea__container) {
    overflow: auto;
}

:deep(.q-scrollarea__content) {
    display: flex;
    align-items: center;
}

.next,
.prev {
    position: absolute;
    top: 50%;
    z-index: 10;
    width: 40px;
    background: $secondary;

    @media screen and (max-width: $breakpoint-mobile) {
        display: none;
    }
}

.next {
    right: 0;
    transform: translateX(50%) translateY(-50%);

    @media screen and (max-width: $breakpoint-mobile) {
        transform: translateX(30%);
    }
}

.prev {
    left: 0;
    transform: translateX(-50%) translateY(-50%);

    @media screen and (max-width: $breakpoint-mobile) {
        transform: translateX(-30%);
    }
}

.next > .q-btn,
.prev > .q-btn {
    width: 38px;
    height: 38px;
    display: block;
    background: $secondary;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @media (max-width: 1280px) {
        width: 28px;
        height: 28px;
    }
}

.q-icon {
    font-size: 24px;
    @media (max-width: 1280px) {
        font-size: 8px;
    }
}

</style>
